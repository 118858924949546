import React from "react";

import PropTypes from "prop-types";

import "./result-card.css";

const ResultCard = (props) => {
  return (
    <div className="result-card-container card">
      <div className="result-card-container1">
        <span className="result-card-text">{props.Value}</span>
      </div>
      <div className="result-card-container2">
        <div
          className="result-card-container3"
          style={{
            backgroundColor:
              props.Available && props.Available ? "#0d643f" : "#ed0000",
          }}
        >
          {props.Available && props.Available ? (
            <span className="result-card-text1">Available</span>
          ) : (
            <span className="result-card-text1">Unavailable</span>
          )}
        </div>
      </div>
    </div>
  );
};

ResultCard.defaultProps = {
  Available: "Available",
  Value: "Result 1",
};

ResultCard.propTypes = {
  Available: PropTypes.bool,
  Value: PropTypes.string,
};

export default ResultCard;
