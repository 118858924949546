import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ProvideAuth } from "./hooks/auth.js";


import './style.css'
import Login from './views/login'
import Home from './views/home'

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Login} exact path="/login" />
        <Route component={Home} exact path="/" />
      </div>
    </Router>
  )
}

ReactDOM.render(
  <ProvideAuth>
    <App />
  </ProvideAuth>,
  document.getElementById("app")
);
