import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useAuth } from "../hooks/auth";

import Header from "../components/header";
import ResultCard from "../components/result-card";
import "./home.css";
import "./loader.css";
import { toast } from "react-toastify";

const Home = (props) => {
  // start
  const { joinRoom, sendMessage, emitter, user, createCheckoutSession } =
    useAuth();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([
    // { name: "cowstop.com", exists: false },
  ]);

  useEffect(() => {
    const redirect = setTimeout(() => {
      console.log("user", user);

      if (!user) {
        console.log("no user should redirect to login");
        //   // props.history.push("/buy-credits");
        props.history.push("/login");
      }
    }, 3000);

    return () => {
      clearTimeout(redirect);
    };
  }, [user]);

  useEffect(() => {
    joinRoom("test");

    const listenerId = emitter.on("message", (data) => {
      console.log("[Home Emitter] data", data);

      if (data.response) {
        // if is of type Array
        if (Array.isArray(data.response)) {
          toast.success("Complete", {
            autoClose: 500,
          });
          setIsLoading(false);
          setResults(data.response);
          return;
        }
      }
    });

    return () => {
      emitter.removeListener("message", listenerId);
    };
  }, []);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (search.length < 5) {
      toast.error("Search must be at least 5 characters long");
      setIsLoading(false);
      return;
    }

    toast.info("Searching... for " + search + "", {
      autoClose: 500,
    });

    setResults([]);

    const messageToSend = {
      type: "app",
      innerType: "search",
      seed: search,
    };

    sendMessage(messageToSend);
    // .then((response) => {
    //   console.log("response", response);

    // })
    // .catch((error) => {
    //   console.log("error", error);
    //   setIsLoading(false);
    // });
  };
  // end

  return (
    <div className="home-container">
      <Helmet>
        <title>plugname.com | great domain names</title>
        <meta property="og:title" content="plugname.com | great domain names" />
      </Helmet>
      <Header></Header>
      <div className="home-container1">
        {/* <pre>{user && JSON.stringify(user, null, 2)}</pre> */}
        <br />
        <div className="home-search">
          <h1 className="home-text">
            <span>
              Find a
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text2">good</span>
            <span> domain</span>
            <br></br>
          </h1>
          <form className="home-container2" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder='"Shoes for cows"'
              className="home-textinput input"
              onChange={handleInputChange}
            />
            <button className="home-button">Search</button>
          </form>
        </div>
        <div className="user-information">
          {user && (
            <div>
              <span>Remaining Credits: {user.credits}</span>
            </div>
          )}

          {user && (
            <div>
              <span>
                Get more credits:{" "}
                <a
                  className="link"
                  onClick={() => {
                    toast.success("Redirecting to checkout page...");
                    createCheckoutSession(user.contact_id).then((response) => {
                      console.log("response", response);
                      window.location.href = response.url;
                    });
                  }}
                >
                  here
                </a>
              </span>
            </div>
          )}
        </div>
        <div className="home-lower">
          <div className="home-results">
            {isLoading && (
              <>
                <br />
                <br />
                <br />
                <br />
                <span className="loader"></span>
              </>
            )}
            {results &&
              results.map((result, index) => {
                return (
                  <ResultCard
                    key={index}
                    Value={result.name}
                    Available={!result.exists}
                  ></ResultCard>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
