// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { EventEmitter } from "../common/event-emitter";

var globalWebsocket = null;

// const host = "localhost:8787";
const host =
  document.location.protocol === "http:"
    ? "localhost:8787"
    : // : "plugname-backend.drbh.workers.dev";
      "api.plugname.com";

const protocol =
  document.location.protocol === "http:" ? "http://" : "https://";

const emitter = new EventEmitter();

const join = () => {
  console.log("Joining room...");
  //   const hostname = window.location.host;
  const hostname = host;

  const roomname = "test-room";

  let lastSeenTimestamp = 0;
  let wroteWelcomeMessages = false;

  if (globalWebsocket !== null) {
    globalWebsocket.close();
  }

  const wss = document.location.protocol === "http:" ? "ws://" : "wss://";

  console.log("Connecting to websocket...");
  let ws = new WebSocket(
    wss + hostname + "/api/room/" + roomname + "/websocket"
  );

  console.log("Websocket created: ");

  // create a global pointer (prob not best... but should work well!)
  // thank god for single threaded langs, am i right?
  globalWebsocket = ws;

  let rejoined = false;
  let startTime = Date.now();

  let rejoin = async () => {
    if (!rejoined) {
      rejoined = true;

      // Don't try to reconnect too rapidly.
      let timeSinceLastJoin = Date.now() - startTime;
      if (timeSinceLastJoin < 10000) {
        // Less than 10 seconds elapsed since last join. Pause a bit.
        await new Promise((resolve) =>
          setTimeout(resolve, 10000 - timeSinceLastJoin)
        );
      }

      // OK, reconnect now!
      join();
    }
  };

  console.log("Setting up websocket event listeners...");
  ws.addEventListener("open", (event) => {
    console.log("WebSocket open!");
    console.log("Sending user info message...");
    ws.send(JSON.stringify({ type: "whoami" }));
  });

  ws.addEventListener("message", (event) => {
    let data = JSON.parse(event.data);
    emitter.emit("message", data);
  });

  ws.addEventListener("close", (event) => {
    console.log("WebSocket closed, reconnecting:", event.code, event.reason);
    rejoin();
  });
  ws.addEventListener("error", (event) => {
    console.log("WebSocket error, reconnecting:", event);
    rejoin();
  });
};

const backend = {
  auth: () => {
    return {
      createCheckoutSession: (userId) => {
        return new Promise((resolve, reject) => {
          fetch(`${protocol}${host}/api/session`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: userId,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              resolve(data);
            });
        });
      },

      onAuthStateChanged: (cb) => {
        if (localStorage.getItem("__user") !== null) {
          const user = JSON.parse(localStorage.getItem("__user"));
          cb(user);
        } else {
          cb(false);
        }
      },
      joinRoom: (roomname) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            //

            join();
            resolve({
              user: {
                roomname,
              },
            });
            //
          }, 300);
        });
      },
      signInWithEmailAndPassword: (phoneNumber) => {
        return new Promise((resolve, reject) => {
          fetch(`${protocol}${host}/api/new`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // mode: "no-cors",
            // mode: "cors",
            // credentials: "include",
            body: JSON.stringify({
              phone_number: phoneNumber,
            }),
          })
            .then((response) => response.text())
            .then((signinResponse) => {
              // console.log({ signinResponse })

              // parse the response
              const user = JSON.parse(signinResponse);

              if (user.error_url) {
                toast.error("Error with phone number.");
                reject(user.error_url);

                return;
              }

              // get the phone_id from the response
              // const phone_id = response.data.phone_id;
              toast.success("Please check your phone for a code.");

              resolve({
                user: {
                  phoneNumber,
                  ...user,
                },
              });
            });
        });
      },
      authenticate: (code, phone_id) => {
        return new Promise((resolve, reject) => {
          fetch(`${protocol}${host}/api/auth`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // mode: "no-cors",
            credentials: "include",
            body: JSON.stringify({
              code: code,
              phone_id: phone_id,
            }),
          }).then((response) => {
            console.log("authenticate response: ");
            console.log({ response });

            if (response.error_url) {
              toast.error("Error with code.");
              reject(response.error_url);

              return;
            }

            toast.success("Success! You are now logged in");

            resolve({
              user: {
                code,
              },
            });
          });
        });
      },
      createUserWithEmailAndPassword: (email, password) => {
        return new Promise((resolve, reject) => {
          resolve({
            user: {
              email,
              password,
            },
          });
        });
      },
      signout: () => {
        return new Promise((resolve, reject) => {
          fetch(`${protocol}${host}/api/signout`, {
            method: "GET",
            mode: "no-cors",
            credentials: "include",
          }).then((response) => {
            console.log("logout response: ");
            console.log({ response });

            if (response.error_url) {
              toast.error("Error logging out.");
              reject(response.error_url);
              return;
            }

            toast.success("Success! You are now logged out");

            resolve({
              user: null,
            });
          });
        });
      },
    };
  },
};

const getUserFromJwt = (token) => {
  console.log(token);
  // decode user from jwt token
  const sections = token.split(".");
  const decodedUser = JSON.parse(atob(sections[1]));
  console.log({ decodedUser });
  return decodedUser;
};

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </authContext.Provider>
  );
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (phoneNumber) => {
    return backend
      .auth()
      .signInWithEmailAndPassword(phoneNumber)
      .then((response) => {
        console.log("signin");
        console.log(response);
        setUser(false);
        return response.user;
      });
  };
  const authenticate = (code, phone_id) => {
    return backend
      .auth()
      .authenticate(code, phone_id)
      .then((user) => {
        // save user to local storage
        localStorage.setItem("__user", JSON.stringify(user));
        setUser(user);
        return user;
      });
  };

  const signout = () => {
    return backend
      .auth()
      .signout()
      .then(() => {
        // remove user from local storage to log user out
        localStorage.removeItem("__user");
        setUser(false);
      });
  };

  const joinRoom = (roomname) => {
    return backend
      .auth()
      .joinRoom(roomname)
      .then(() => {
        // setUser(false);
      });
  };

  const sendMessage = (message) => {
    return new Promise((resolve, reject) => {
      if (!globalWebsocket) {
        console.log("No websocket connection");
        return;
      }
      // make a unique id
      const id = Math.random().toString(36).substring(7);
      // add the id to the message
      message.id = id;
      // add the message to the emitter
      emitter.once(id, (message) => {
        console.log("Received message:", message);
        resolve(message);
      });

      // send the message
      globalWebsocket.send(JSON.stringify(message));
    });
  };

  const createCheckoutSession = (userId) => {
    console.log("createCheckoutSession");
    return backend
      .auth()
      .createCheckoutSession(userId)
      .then((response) => {
        // console.log("createCheckoutSession response");
        // console.log(response);
        return response;
      });
  };

  useEffect(() => {
    const randomId = Math.random().toString(36).substring(7);
    console.log("Connecting with id", randomId);
    emitter.on("message", (message) => {
      // console.log("Received message:", message);
      if (message.type === "user") {
        // TODO: better parsing of user data
        setUser(message.results[0]);
      }
    });
    return () => {
      console.log("Cleaning up id", randomId);
      emitter.removeAllListeners("message");
    };
  }, []);

  useEffect(() => {
    const unsubscribe = backend.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(false);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signin,
    joinRoom,
    authenticate,
    signout,
    sendMessage,
    emitter,
    createCheckoutSession,
  };
}
