import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import { useAuth } from "../hooks/auth";

import { toast } from "react-toastify";

import Header from "../components/header";
import "./login.css";

const Login = (props) => {
  // start
  const [step, setStep] = React.useState(1);
  const incrementStep = () => {
    setStep(step + 1);
  };

  const [inputValue, setInputValue] = React.useState("");

  const { signin, authenticate } = useAuth();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneId, setPhoneId] = React.useState("");
  const handleInputChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    let parsedPhoneNumber = phoneNumber.replace(/\D/g, "");
    console.log("parsedPhoneNumber", parsedPhoneNumber);

    if (parsedPhoneNumber.length < 10) {
      toast.error("Your number is not long enough. Need 10 digits.");
      return;
    }

    if (parsedPhoneNumber.length > 10) {
      toast.error("Your number is too long. Need 10 digits.");
      return;
    }

    // add a +1 to the front
    parsedPhoneNumber = "+1" + parsedPhoneNumber;

    signin(parsedPhoneNumber)
      .then((response) => {
        // console.log("signin");
        // console.log(response);
        // setPhoneId(response.user.phone_id);
        setPhoneId(response.phone_id);
        incrementStep();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [code, setCode] = React.useState("");

  const handleCodeInputChange = (event) => {
    setCode(event.target.value);
  };
  const handleCodeSubmit = (event) => {
    event.preventDefault();
    console.log("code", code, "phoneId", phoneId);
    authenticate(code, phoneId).then(() => {
      console.log("done authenticating");
      setTimeout(() => {
        props.history.push("/");
      }, 100);
    });
  };

  useEffect(() => {
    setInputValue(step == 1 ? phoneNumber : code);
  }, [step, phoneNumber, code]);

  // end

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - plugname.com | great domain names</title>
        <meta property="og:title" content="plugname.com | great domain names" />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="login-container1">
        <div className="login-container2">
          <h1 className="login-text">
            <span>{step == 1 ? "Passwordless login" : "Verify Code"}</span>
            <br></br>
          </h1>
          <form
            className="login-form"
            onSubmit={step == 1 ? handleSubmit : handleCodeSubmit}
          >
            <input
              type="text"
              required
              autoFocus
              placeholder="1233456789"
              className="login-input input"
              value={inputValue}
              onChange={step == 1 ? handleInputChange : handleCodeInputChange}
            />
            <button className="login-button button">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
